import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ThemeLanguageToggle } from "../CustomComponents/ThemeLanguageToggle";
import { getFromLocalStorage, saveToLocalStorage } from "../utils/localStorageHandler";
import { useLanguage } from "../contexts/LanguageContext";

const translations = {
    en: {
        admin: "Admin",
        judge: "Judge",
        myProfile: "My Profile",
        findTournaments: "Find Tournaments",
        myTournaments: "My Tournaments",
        myMatches: "My Matches",
        tieBreak: "Tie Breaks",
        logout: "Logout",
        createTournament: "Create Tournament",
        tournamentSettings: "Tournament Settings",
        tournamentDraws: "Tournament Draws",
        tournamentMatches: "Tournament Matches",
        findProfiles: "Find Profiles",
        setPermissions: "Set Permissions",
        ties: "Ties",
        termsAndConditions: "Terms and Conditions"
    },
    pt: {
        admin: "Admin",
        judge: "Juiz",
        myProfile: "Meu Perfil",
        findTournaments: "Encontrar Torneios",
        myTournaments: "Meus Torneios",
        myMatches: "Minhas Partidas",
        tieBreak: "Empates",
        logout: "Sair",
        createTournament: "Criar Torneio",
        tournamentSettings: "Configurações de Torneio",
        tournamentDraws: "Sorteios de Torneio",
        tournamentMatches: "Partidas de Torneio",
        findProfiles: "Buscar Perfis",
        setPermissions: "Definir Permissões",
        ties: "Empates",
        termsAndConditions: "Termos e Condições"
    }
};

const NavbarItem = ({ to, children, onClick, isMobile }) => (
    <Link
        to={to}
        className={`text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium ${
            isMobile ? 'block w-full text-left' : ''
        }`}
        onClick={onClick}
    >
        {children}
    </Link>
);

const Dropdown = ({ title, items, isMobile }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (isMobile) {
        return (
            <div className="space-y-1">
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="w-full text-left text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium focus:outline-none"
                >
                    {title}
                </button>
                {isOpen && (
                    <div className="pl-4 space-y-1">
                        {items.map((item, index) => (
                            <NavbarItem key={index} to={item.to} isMobile={true}>
                                {item.label}
                            </NavbarItem>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="relative" ref={dropdownRef}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium focus:outline-none"
            >
                {title}
            </button>
            {isOpen && (
                <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {items.map((item, index) => (
                            <Link
                                key={index}
                                to={item.to}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 transition duration-150 ease-in-out"
                                role="menuitem"
                                onClick={() => setIsOpen(false)}
                            >
                                {item.label}
                            </Link>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export const Navbar = () => {
    const [userRole, setUserRole] = useState('');
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const navigate = useNavigate();
    const { language } = useLanguage();
    const t = translations[language];

    useEffect(() => {
        const role = getFromLocalStorage('userPermissions', 'competitor');
        setUserRole(role || '');
    }, []);

    const adminMenuItems = [
        { label: t.createTournament, to: '/admin/create-tournament' },
        { label: t.tournamentSettings, to: '/admin/tournament-settings' },
        { label: t.tournamentDraws, to: '/admin/tournament-draws' },
        { label: t.tournamentMatches, to: '/admin/tournament-matches' },
        { label: t.findProfiles, to: '/admin/find-profile' },
        { label: t.setPermissions, to: '/admin/set-permissions' },
        { label: t.ties, to: '/admin/ties' },
        { label: t.termsAndConditions, to: '/admin/terms-and-conditions' },
    ];

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const onLogout = () => {
        const language = getFromLocalStorage('language', 'pt');
        const theme = getFromLocalStorage('theme', 'pt');
        localStorage.clear();
        saveToLocalStorage('language', language);
        saveToLocalStorage('theme', theme);
        navigate('/login');
    }

    return (
        <nav className="bg-gray-800 border-b border-gray-700">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                            <span className="text-white font-bold">Abada Capoeira</span>
                        </div>
                        <div className="hidden md:block ml-10">
                            <div className="flex items-baseline space-x-4">
                                {userRole === 'admin' && (
                                    <Dropdown title={t.admin} items={adminMenuItems} />
                                )}
                                {(userRole === 'judge' || userRole === 'admin') && (
                                    <NavbarItem to="/judge">{t.judge}</NavbarItem>
                                )}
                                <NavbarItem to="/profile">{t.myProfile}</NavbarItem>
                                <NavbarItem to="/tournaments">{t.myTournaments}</NavbarItem>
                                <NavbarItem to="/matches">{t.myMatches}</NavbarItem>
                                <NavbarItem to="/enroll">{t.findTournaments}</NavbarItem>
                            </div>
                        </div>
                    </div>
                    <div className="hidden md:block">
                        <div className="ml-4 flex items-center md:ml-6">
                            <ThemeLanguageToggle />
                            <button
                                onClick={onLogout}
                                className="ml-3 px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                            >
                                {t.logout}
                            </button>
                        </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                        <button
                            onClick={toggleMobileMenu}
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                        >
                            <span className="sr-only">Open main menu</span>
                            {!isMobileMenuOpen ? (
                                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                            ) : (
                                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            )}
                        </button>
                    </div>
                </div>
            </div>

            <div className={`md:hidden ${isMobileMenuOpen ? 'block' : 'hidden'}`}>
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                    {userRole === 'admin' && (
                        <Dropdown title={t.admin} items={adminMenuItems} isMobile={true} />
                    )}
                    {(userRole === 'judge' || userRole === 'admin') && (
                        <NavbarItem to="/judge" isMobile={true}>{t.judge}</NavbarItem>
                    )}
                    <NavbarItem to="/profile" isMobile={true}>{t.myProfile}</NavbarItem>
                    <NavbarItem to="/tournaments" isMobile={true}>{t.myTournaments}</NavbarItem>
                    <NavbarItem to="/matches" isMobile={true}>{t.myMatches}</NavbarItem>
                    <NavbarItem to="/enroll" isMobile={true}>{t.findTournaments}</NavbarItem>
                </div>
                <div className="pt-4 pb-3 border-t border-gray-700">
                    <div className="flex items-center px-5">
                        <ThemeLanguageToggle />
                        <button
                            onClick={onLogout}
                            className="ml-auto px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                        >
                            {t.logout}
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
};
