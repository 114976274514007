import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import { Login } from "./UIComponents/Login";
import { SignUp } from "./UIComponents/SignUp";
import { ResetPassword } from "./UIComponents/ResetPassword";
import { Profile } from "./UIComponents/Profile";
import { Enroll } from "./UIComponents/Enroll";
import { Matches } from "./UIComponents/Matches";
import { Tournaments } from "./UIComponents/Tournaments";
import { FindProfile } from "./UIComponents/FindProfile";
import { SetPermissions } from "./UIComponents/SetPermissions";
import { Judge } from "./UIComponents/Judge";
import { CreateTournament } from "./UIComponents/CreateTournaments";
import { TournamentDraws } from "./UIComponents/TournamentDraws";
import { TournamentSettings } from "./UIComponents/TournamentSettings";
import { TournamentMatches } from "./UIComponents/TournamentMatches";
import { Ties } from "./UIComponents/Ties";
import { EditTermsAndConditions } from "./UIComponents/EditTermsAndConditions";
import { TermsAndConditions } from "./UIComponents/TermsAndConditions";

import { AuthenticatedLayout } from "./layouts/AuthenticatedLayout";
import { NonAuthenticatedLayout } from "./layouts/NonAuthenticatedLayout";

import { getFromLocalStorage } from "./utils/localStorageHandler";

import { LanguageProvider } from './contexts/LanguageContext';

import 'toastr/build/toastr.min.css';


function App() {
    return (
        <LanguageProvider>
            <BrowserRouter>
                <div className="App">
                    <Routes>
                        <Route element={<NonAuthenticatedLayout />}>
                            <Route path='/login' element={<Login />} />
                            <Route path='/signup' element={<SignUp />} />
                            <Route path='/reset-password' element={<ResetPassword />} />
                            <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
                        </Route>

                        <Route path='/' element={<AuthenticatedLayout />}>
                            <Route index element={<Navigate to="/profile" replace />} />
                            <Route path='profile' element={<Profile />} />
                            <Route path="enroll" element={<Enroll />} />
                            <Route path="tournaments" element={<Tournaments />} />
                            <Route path="matches" element={<Matches />} />
                            <Route path="judge" element={<Judge />} />
                            <Route path="admin/create-tournament" element={<CreateTournament />} />
                            <Route path="admin/tournament-draws" element={<TournamentDraws />} />
                            <Route path="admin/tournament-settings" element={<TournamentSettings />} />
                            <Route path="admin/find-profile" element={<FindProfile />} />
                            <Route path="admin/set-permissions" element={<SetPermissions />} />
                            <Route path="admin/tournament-matches" element={<TournamentMatches />} />
                            <Route path="admin/ties" element={<Ties />} />
                            <Route path="admin/terms-and-conditions" element={<EditTermsAndConditions />} />
                        </Route>
                        {getFromLocalStorage('authToken', false) ? (
                            <Route path="*" element={<Navigate to="/profile" replace />} />
                        ) : (
                            <Route path="*" element={<Navigate to="/login" replace />} />
                        )}
                    </Routes>
                </div>
            </BrowserRouter>
        </LanguageProvider>
    );
}

export default App;