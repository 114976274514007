/**
 * Tomas Costantino
 *
 * Server module for tournament settings
 */

import axios from "axios";

export async function getTournaments() {
    return axios.get(`${process.env.REACT_APP_API_BASE}/tournament`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error('Server request failed', error);
        })
}

export async function getTournamentsByUserId(userId) {
    return axios.get(`${process.env.REACT_APP_API_BASE}/tournament/by-user-id/${userId}`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error.response.data.detail);
        })
}

export async function getFinishedTournaments(userId) {
    return axios.get(`${process.env.REACT_APP_API_BASE}/tournament/finished/by-user-id/${userId}`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error.response.data.detail);
        })
}

export async function getTournamentDrawById(tournamentId) {
    return axios.get(`${process.env.REACT_APP_API_BASE}/tournament/by-tournament-id/${tournamentId}/draw`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error.response.data.detail);
        })
}

export async function getTournamentDetails(tournamentId) {
    return axios.get(`${process.env.REACT_APP_API_BASE}/tournament/by-tournament-id/${tournamentId}/details`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error.response.data.detail);
        })
}

export async function getAvailableTournamentsForUserId(userId) {
    return axios.get(`${process.env.REACT_APP_API_BASE}/tournament/available/by-user-id/${userId}`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error.response.data.detail);
        })
}

export async function getTournamentsForAdmin() {
    return axios.get(`${process.env.REACT_APP_API_BASE}/tournament/admin`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error.response.data.detail);
        })
}


export async function getTournamentDrawForAdmin(tournamentId) {
    return axios.get(`${process.env.REACT_APP_API_BASE}/tournament/by-tournament-id/${tournamentId}/draw/admin`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error.response.data.detail);
        })
}


export async function getTournamentExportData(tournamentId) {
    return axios.get(`${process.env.REACT_APP_API_BASE}/tournament/by-tournament-id/${tournamentId}/export`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error.response.data.detail);
        })
}
